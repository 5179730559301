import Image from 'components/Image';
import { StyledStructured } from 'components/StyledStructured';
import { Navigator } from 'components/Layout/Navigator';
import CubeLinkIcon from 'assets/icons/icon-arrow-right.svg';
import { HeroWrapper, Card, Cards, DoctorPicture, LinkIcon, DoctorName, DoctorRole, DoctorInfo } from './styles';

const Hero = ({ pageData }) => {
  const { title, doctors } = pageData;

  return (
    <HeroWrapper>
      <StyledStructured
        data={title}
      />
      <Cards>
        {doctors.map((doctor) => {
          const { photo, fullName, role, id } = doctor;
          return (
            <Card key={id} as={Navigator} recordId={id}>
              <DoctorPicture>
                <Image image={photo} alt={fullName} />
              </DoctorPicture>
              <DoctorInfo>
                <DoctorName>{fullName}</DoctorName>
                <LinkIcon as={CubeLinkIcon} />
              </DoctorInfo>
              <DoctorRole>{role}</DoctorRole>
            </Card>
          );
        })}
      </Cards>
    </HeroWrapper>
  );
};

export { Hero };
