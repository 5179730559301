import styled from 'styled-components';
import { RoundedContainer } from 'styles/elements';
import { media } from 'styles/mixins';

export const HeroWrapper = styled(RoundedContainer)`
  h1 {
    font-size: 54px;
    font-weight: 600;
    line-height: 54px;
        
        em {
              color: black;
              font-style: normal;
        }
        
        ${media.tablet`
          font-size: 40px;
          font-weight: 600;
          line-height: 54px;
        `}

      ${media.mobile`
        font-size: 27px;
        font-weight: 500;
        line-height: 36px;
      `}
  }
`;

export const Cards = styled.div`
  display: flex;
  gap: 36px;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 54px;

    ${media.mobile`
        margin-top: 32px;
      `}
`;

export const Card = styled.a`
    display: flex;
    flex-direction: column;
    width: calc(100% / 3 - 24px);

    ${media.tablet`
        width: calc(100% / 2 - 18px);
    `}

    ${media.mobile`
        width: 100%;
        
        .gatsby-image-wrapper {
          width: 100%;
        }
    `}
`;

export const DoctorPicture = styled.div`
    border-radius: 20px;
    overflow: hidden;
`;

export const DoctorInfo = styled.div`
    display: flex;
    position: relative;
    padding-right: 54px;
    
    svg {
        position: absolute;
        top: 19px;
        right: 0;
    }
`;

export const DoctorName = styled.div`
    font-size: 27px;
    line-height: 36px;
    margin-top: 22px;
`;

export const DoctorRole = styled.div`
    margin-top: 13px;
    font-size: 15px;
    line-height: 18px;
    color: #6E707C;
    font-weight: 400;
    padding-right: 54px;
`;

export const LinkIcon = styled.svg``;
