import { graphql } from 'gatsby';
import { PageWrapper } from 'components/Layout/PageWrapper';
import { MainContainer } from 'components/UI/Containers';
import { Hero } from './Hero';

const TeamPageTemplate = ({ data, pageContext }) => {
  const {
    datoCmsSymmetryTeamPage: pageData,
  } = data;
  const { seo } = pageData;
  const { seoTitle, seoDescription, seoImage } = seo || {};
  return (
    <PageWrapper
      pageData={pageContext}
      {...{ seoTitle, seoDescription, seoImage }}
    >
      <MainContainer>
        <Hero {...{ pageData }} />
      </MainContainer>
    </PageWrapper>
  );
};

export default TeamPageTemplate;

export const query = graphql`
  query TeamPageQuery($locale: String!) {
    datoCmsSymmetryTeamPage(locale: { eq: $locale }) {
      locale
      title {
        value
      }
      doctors {
        photo {
          url
          gatsbyImageData(width: 398, height: 490, placeholder: TRACED_SVG)
          format
        }
        fullName
        role
        id: originalId
      }
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
    }
  }
`;
